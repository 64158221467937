import httpClient  from '../api'; 
const API_CONTROLLER = 'vehicle/'; 


export default { 
    delete(id){ 
        let url = API_CONTROLLER + 'delete';
        return httpClient.delete(url, {
            params: {
                id: id
            }
        });
    },

    add(data) { 
        let url = API_CONTROLLER + 'add';
        return httpClient.post(url, data);
    },

    edit(data) { 
        let url = API_CONTROLLER + 'edit';
        return httpClient.post(url, data);
    },

    getById(id) { 
        let url = API_CONTROLLER + 'get-by-id';
        return httpClient.get(url, {
            params: {
                id: id
            }
        });
    },

    filter(search) { 
        let url = API_CONTROLLER + 'filter';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                groups: search.groups,
                isVehicle: search.isVehicle,
                licensePlate: search.licensePlate,
                ownerId: search.ownerId
            }
        });
    },

    getVehicles(search) { 
        let url = API_CONTROLLER + 'get-vehicles';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                parentId: search.parentId,
                groupId: search.groupId,
                licensePlate: search.licensePlate
            }
        });
    },

    getAllVehicles(search) { 
        let url = API_CONTROLLER + 'get-all-vehicles';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                parentId: search.parentId,
                groupId: search.groupId,
                licensePlate: search.licensePlate
            }
        });
    },


    getVehicleByLicensePlate(licensePlate) { 
        let url = API_CONTROLLER + 'get-by-license-plate';
        return httpClient.get(url, {
            params: {
                licensePlate: licensePlate
            }
        });
    },
}